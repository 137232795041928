.projectItem {
  height: 100vh;
  background-color: #f8f7f1;
  display: flex;
  /*  justify-content: center; */
  align-items: center;
  flex-direction: column;
  padding-top: 1rem;
}
.projectItem main {
  display: flex;
  width: 9rem;
  gap: 0.3rem;
  align-items: center;
}
.projectItem .projectInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.projectItem .projectVideo {
  flex: 2;
}
.projectItem .projectVideo2 {
  flex: 2;
  display: flex;
  align-items: center;
}
.projectItem .projectVideo img {
  width: 100%;
}
.projectItem .projectVideo2 .iphone {
  height: 3rem;
}
.projectItem .projectVideo2 .mac {
  width: 100%;
}
.projectItem .projectInfo a {
  color: white;
  font-weight: 600;
}
