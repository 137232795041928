@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400&family=Source+Sans+Pro:wght@400;600;700&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");
* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  font-family: "Source Sans Pro", "sans-serif";
}
a {
  text-decoration: none;
  color: black;
}

/* about area */
.img img {
  width: 3.6rem;
  animation: profile_animate 4s ease-in-out infinite;
  border: 0.07rem solid rgba(0, 0, 0, 0.3);
}
@keyframes profile_animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

/* media */
@media (min-width: 1200px) {
  html {
    font-size: 8.333vw;
  }
  body {
    font-size: 0.15rem;
  }
}
@media (max-width: 1199px) {
  html {
    font-size: 100px;
  }
  body {
    font-size: 0.15rem;
  }
}
@media (max-width: 1100px) {
  .home main .rightInfo {
    display: none;
  }
}
@media (max-width: 920px) {
  /* Skills part */
  .skills {
    height: 100% !important;
  }
  .skills .skillset-bottom {
    align-items: center;
    flex-direction: column;
    gap: 0.2rem;
  }
  .skills .skillItem {
    width: 80% !important;
    height: 1.5rem !important;
    justify-content: center !important;
    gap: 0.1rem !important;
    padding: 0 !important;
    align-items: center !important;
  }
  /* projects part */
  .projectItem {
    height: 10rem !important;
  }
  .projectItem main {
    width: 80% !important;
    align-items: center;
    flex-direction: column;
  }
  .projectItem .projectInfo {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: center;
    line-height: 0.3rem;
  }
  .projectItem h1 {
    margin: 0.2rem 0;
  }
  .projectItem .projectInfo button {
    align-self: flex-start;
  }
  /* experience */
  .experience main {
    width: 80% !important;
    flex-direction: column;
  }
  .experience .experienceComment {
    margin-top: 0.5rem;
  }
}
@media (max-width: 680px) {
  html {
    /*     font-size: 19.5122vw; */
    font-size: 100px;
  }
  .home main {
    justify-content: space-around !important;
    flex-direction: column;
    align-items: center;
  }
  .home main .leftInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.2rem;
    padding: 0.1rem 0;
    align-items: center;
    height: 1.7rem;
  }
  .home {
    position: relative;
    overflow: hidden;
  }
  .nav ul {
    position: absolute;
    top: 0;
    right: -3rem;
    background-color: white;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    height: 100vh;
    width: 1.5rem;
    padding: 0.3rem 0;
    transition: right 1s;
  }
  .nav .close {
    display: block !important;
  }
  .nav .burger {
    display: block !important;
    cursor: pointer;
  }
  /* about part */
  .about {
    height: 100% !important;
  }
  .myInfo {
    flex-direction: column;
  }
  .text {
    padding: 0 0.4rem;
  }
  /* project part */
  .projectItem {
    height: 8rem !important;
  }
}
