.home {
  height: 100vh;
  background-color: #f8f7f1;
  padding: 0 0.66rem;
  color: #0d2f3f;
}
.home main {
  height: 90vh;
  display: flex;
  justify-content: space-between;
}
.home .leftInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem 0;
  align-items: center;
}
.home .scroll {
  animation: scroll 2s ease-out infinite;
}
.home .rightInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 1rem 0;
}
.home a:hover {
  color: green;
}
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(10px);
    opacity: 0.3;
  }
  100% {
    transform: translateY(10px);
    opacity: 0;
  }
}
