.skills {
  height: 100vh;
  background-color: #f8f7f1;

  color: #0d2f3f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skills main {
  width: 9rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.skills .skillset {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.skills .skillset-header {
  display: flex;
  justify-content: center;
  gap: 0.3rem;
}
.skills .skillset-bottom {
  display: flex;
  justify-content: space-between;
}
