.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
}
.nav ul {
  display: flex;

  align-items: center;
  gap: 0.25rem;
}
.nav .close {
  display: none;
}
.nav .burger {
  display: none;
  font-size: 0.3rem;
}
.nav .burgerClose {
  display: none;
}

.nav .icon {
  font-size: 0.24rem;
  font-weight: 600;
}
.nav a {
  color: #0d2f3f;
}
.nav a:hover {
  color: green;
}
.nav .gift {
  width: 0.4rem;
  height: 0.4rem;
  border: 0.01rem solid white;
  text-align: center;
  line-height: 0.4rem;
  background-color: white;
  border-radius: 0.4rem;
}
.nav .gift:hover {
  transition: 0.3s;
  transform: scale(1.2);
}
.nav li {
  font-size: 0.16rem;
}
