.experience {
  height: 100vh;
  background-color: #f8f7f1;
  display: flex;
  /*  justify-content: center; */
  align-items: center;
  flex-direction: column;
}
.experience main {
  margin-top: 0.5rem;
  display: flex;
  width: 9rem;
  gap: 0.3rem;
  align-items: center;
}
.experience .experienceInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.experienceInfo li {
  margin-bottom: 0.15rem;
}
.experience .experienceComment {
  flex: 2;
  display: flex;

  justify-content: center;
}
.experience .experienceComment .comment {
  position: relative;
  background-color: white;
  height: 2.7rem;
  width: 4.2rem;
  border-radius: 0.3rem;
  padding: 0.4rem 0.5rem 0rem 0.5rem;
}
.experience .experienceComment .comment p,
.experience .experienceComment .comment h3 {
  text-align: center;
}
.experience .experienceComment .comment p {
  line-height: 0.22rem;
}
.experience .experienceComment .comment hr {
  margin: 0.1rem 0;
}
.experience .experienceComment .commentImg {
  position: absolute;

  top: -0.5rem;
  left: 1.7rem;
}
.experience .experienceComment .commentImg img {
  width: 0.8rem;
  border-radius: 0.4rem;

  /*  position: absolute; */
}
